.LevelsPage__cardButtonWrapper___13FI_ {
  width: 100%;
  display: block !important;
  text-align: left;
}

.LevelsPage__backButton___KPhKq {
  margin: 2em 0 !important;
}

.LevelsPage__gameboardContainer___1cuGe {
  width: 100%;
  height: 15em;
  background-image: linear-gradient(rgb(240, 240, 240) 2px, transparent 2px),
    linear-gradient(90deg, rgb(240, 240, 240) 2px, transparent 2px),
    linear-gradient(rgb(245,245,245) 1px, transparent 1px),
    linear-gradient(90deg, rgb(245,245,245) 1px, transparent 1px);
  background-size: 20px 20px, 20px 20px, 5px 5px, 5px 5px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  position: relative;
  overflow: hidden;
}

.LevelsPage__levelItemWrapper___2D4D4 {
  padding: 2em 0;
  background-color: #f1f1f1;
}

.LevelsPage__levelSelectionTitle___1Bw5V {
  padding: 3em 0 1em 0;
}

.LevelsPage__backIcon___2RaR1 {
  margin-right: 0.4em;
}

.LevelsPage__starContainer___3KWlu {
  margin: 0.5em 0 -0.5em -0.35em;
}

.LevelsPage__star___GaVwJ {
  font-size: 2.3em !important;
  color: rgb(231, 231, 231);
}

.LevelsPage__starFilled___HdTtL {
  color: rgb(248, 192, 50);
}

.LevelsPage__star___GaVwJ:nth-child(2) {
  margin: 0 -0.2em;
}

.LevelsPage__levelPoints___27VoK {
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 1em;
  padding: 0 0.4em;
  font-size: 0.8em;
  display: inline;
  font-size: 0.5em;
  vertical-align: middle;
  margin-left: 1em;
}
