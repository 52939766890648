.FeedbackPopup__star___2y46u {
  font-size: 7em !important;
  color: rgb(231, 231, 231);
}

.FeedbackPopup__starFilled___aiTTR {
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-name: FeedbackPopup__tada___3--L9;
}

.FeedbackPopup__starContainer___N3UCs {
  margin: 1em auto 0 auto;
  display: table;
  white-space: nowrap;
}

.FeedbackPopup__star___2y46u:nth-child(2) {
  margin-bottom: 0.2em;
}

/* extracted from https://github.com/daneden/animate.css */
@keyframes FeedbackPopup__tada___3--L9 {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    color: rgb(248, 192, 50);
  }
}

@keyframes FeedbackPopup__headShake___19rRm {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-12px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(10px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(4px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.FeedbackPopup__failIcon___3bfKE {
  font-size: 7em !important;
  color: #f44336;
  border: 0.08em solid;
  border-radius: 1em;
  display: block !important;
  margin: 0.15em auto 0.3em auto !important;
  animation-duration: 0.7s;
  animation-delay: 0.15s;
  animation-fill-mode: forwards;
  animation-name: FeedbackPopup__headShake___19rRm;
}

.FeedbackPopup__pointsContainer___-PVLi {
  margin: 0 auto 2em auto;
}

.FeedbackPopup__pointsContainer___-PVLi span {
  margin-right: 0.2em;
  margin-left: -0.8em;
}

.FeedbackPopup__slimHr___1guqL {
  border: none;
  height: 1px;
  background-color: #e9e9e9;
  margin: 1em 0;
}

.FeedbackPopup__hintContainer___25BYJ {
  padding: 0.5em 0 0 1em;
}
