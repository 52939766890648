.GamePage__matchViewportHeight___2n72C {
  height: calc(100vh - var(--app-bar-height)) !important;
  z-index: -1;
}

.GamePage__gridBackground___vZEJM {
  background-image: linear-gradient(rgb(240, 240, 240) 2px, transparent 2px),
    linear-gradient(90deg, rgb(240, 240, 240) 2px, transparent 2px),
    linear-gradient(rgb(245,245,245) 1px, transparent 1px),
    linear-gradient(90deg, rgb(245,245,245) 1px, transparent 1px);
  background-size:100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.GamePage__gridBackground___vZEJM::-webkit-scrollbar {
  display: none;
}

.GamePage__startButton___2waon {
  bottom: 1em;
  position: fixed !important;
  left: 1em;
}

.GamePage__infoResetButton___pKTYs {
  bottom: 1em;
  position: fixed !important;
  right: 1em;
}

.GamePage__sidebar_upper___3qdwq {
  padding-top: 20px;
}

.GamePage__service_header___17-zX {
  text-align: center;
}

.GamePage__draggable_wrapper___2mrf7 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
