.YoutubePopup__dialogContent___1UkHa {
  padding: 0 !important;
}

.YoutubePopup__videoIcon___2ztfl {
  font-size: 1em !important;
  vertical-align: middle;
  margin-left: 0.2em;
}


