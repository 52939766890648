:root {
  --app-bar-height: 64px;
}

.Header__toolBar___1jCgU {
  justify-content: space-between;
  height: var(--app-bar-height);
  white-space: nowrap;
}

.Header__fixed_size_app_bar___35_ED {
  height: var(--app-bar-height);
}

.Header__toolBarName___X0MJu {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 19.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 304 182' style='enable-background:new 0 0 304 182;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st1%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFF;%7D %3C/style%3E %3Cg%3E %3Cg%3E %3Cpath class='st1' d='M273.5,143.7c-32.9,24.3-80.7,37.2-121.8,37.2c-57.6,0-109.5-21.3-148.7-56.7c-3.1-2.8-0.3-6.6,3.4-4.4 c42.4,24.6,94.7,39.5,148.8,39.5c36.5,0,76.6-7.6,113.5-23.2C274.2,133.6,278.9,139.7,273.5,143.7z'/%3E %3Cpath class='st1' d='M287.2,128.1c-4.2-5.4-27.8-2.6-38.5-1.3c-3.2,0.4-3.7-2.4-0.8-4.5c18.8-13.2,49.7-9.4,53.3-5 c3.6,4.5-1,35.4-18.6,50.2c-2.7,2.3-5.3,1.1-4.1-1.9C282.5,155.7,291.4,133.4,287.2,128.1z'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2.2em;
  background-position: 0 0.5em;
  margin-bottom: -0.5em !important;
  padding-bottom: 0.5em;
}

.Header__toolBarName___X0MJu::after {
  content: "Boot Camp";
}

.Header__pointsName___X7N_M::after {
  content: "Punkte"
}

@media (max-width: 550px) {
  .Header__toolBarName___X0MJu::after {
    content: "BC";
  }
  .Header__pointsName___X7N_M::after {
      content: "Pkt."
  }
}

@media (max-width: 375px) {
  .Header__toolBarNameContainer___2rbdY {
    display: none !important;
  }
}

.Header__link___2dj9X {
  color: white;
  text-decoration: none;
}

.Header__starContainer___M4OY_ {
  padding-left: 0.5em;
}

.Header__star___3PRlb {
  font-size: 2.3em !important;
  color: rgb(231, 231, 231);
}

.Header__starFilled___2RL3x {
  color: rgb(248, 192, 50);
}

.Header__star___3PRlb:nth-child(2) {
  margin: 0 -0.2em;
}

.Header__levelName___3AmJL {
  width: 100%;
  padding-right: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Header__levelPoints___lZSgy {
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 1em;
  padding: 0 0.4em;
  font-size: 0.8em;
}
