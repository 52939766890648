.CreditsPage__backButton___3OnZE {
    margin: 2em 0 !important;
}

.CreditsPage__creditsWrapper___1up1Y {
    padding: 2em 0;
    background-color: #f1f1f1;
}

.CreditsPage__creditsTitle___arOu- {
    padding: 3em 0 1em 0;
}

.CreditsPage__backIcon___3BR62 {
    margin-right: 0.4em;
}

.CreditsPage__iconCreditsImage___3g-lr {
    display: inline-block;
    margin-right: 0.4em;
    background-color: #A1A1A1;
    vertical-align: middle;
    width: 3em;
}

.CreditsPage__card___3CKXw {
    padding: 1em 0 1em 0;
}
