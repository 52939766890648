.ErrorPage__backIcon___L9yPN {
  margin-right: 0.4em;
}

.ErrorPage__buttonWrapper___a5elK {
  text-align: center;
  margin-top: 2em;
}

.ErrorPage__wrapper___FzA6h {
  padding-top: 2em;
}
