.StartPage__button_group___J6JHh {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
}

.StartPage__start_button___25ZwL {
  margin-bottom: 10px!important;
}

.StartPage__title___2D3Iw {
  padding: 3em 0 1em 0;
}

.StartPage__icons___Z2ADu {
  display: flex;
  justify-content: center;
}

.StartPage__icons_wrapper___2NrFf {
  padding: 2.5em 0 1em 0;
}

.StartPage__titleName___2Y3Oa {
  margin-bottom: -0.5em !important;
  padding-bottom: 0.5em;
}

.StartPage__icon___Ab_zQ {
  flex-grow: 1;
  cursor: pointer;
  transition: transform .2s;
}

.StartPage__icon___Ab_zQ:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.StartPage__icon___Ab_zQ:hover {
  transform: scale(1.15);
}

.StartPage__icon_active___1ij_T {
  transform: scale(1.1);
}

.StartPage__content___zbRRW {
  min-height: calc(100vh - 340px)
}

footer {
  position: fixed;
  bottom: 20px;
  left: 0;
  height: 16px;
  font-size: 16px;
}

@media(min-width: 700px) {
  .StartPage__button_group___J6JHh {
    flex-direction: row;
  }
  .StartPage__icons_wrapper___2NrFf {
    padding: 4em 0 1em 0;
  }
  .StartPage__content___zbRRW {
    min-height: calc(100vh - 250px)
  }
}
