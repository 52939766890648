.Droppable__droppable___3Ynme {
  height: 17vh;
  width: 17vh;
  border: 2px solid black;
  margin: 2px;
  border-radius: 4px;
}

.Droppable__droppableHover___3jmdH {
  background-color: rgba(216, 216, 216, 0.3);
  border: 4px solid black;
  margin: 0;
}

.Droppable__droppableActive___3d1xf {
  border-style: dashed;
}
