.Draggable__dragging___1dlDW {
  opacity: 0.5;
}

.Draggable__draggable___260wo {
  cursor: grab;
}

/* we need this so the child content fills the entire space */
.Draggable__draggable___260wo, .Draggable__draggable___260wo > div {
  height: 100%;
  width: 100%;
}
