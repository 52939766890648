.IconElement__iconElement___1P6wD {
  border-radius: 6px;
  -webkit-user-drag: none;
  height: 17vh;
  width: 17vh;
}

.IconElement__thumbnailIcon___E2eaX {
  height: 3em;
  width: 3em;
}
