.SplitterPanel__panelWrapper___3M-Kp {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.SplitterPanel__panelInner___2mbi6 {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: auto;
}
