.AWSPaletteItem__paletteItem___CNJ3v {
  position: relative;
  height: 15vh;
  width: 15vh;
  min-width: 15vh;
  min-height: 15vh;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  border-radius: 4px;
  overflow: hidden;
  margin: 10px;
}

.AWSPaletteItem__infoButton___ZzZ0O {
  padding: 4px !important;
  position: absolute !important;
  top: 0;
  right: 0;
  color: white !important;
}
