.UsernameButton__username___5wk_6 {
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UsernameButton__userIcon___2DpyM {
 margin-left: 0.3em;
}

.UsernameButton__headerButton___2i_9t {
  max-width: 12em;
}

@media (max-width: 550px) {
  .UsernameButton__headerButton___2i_9t {
    max-width: 9em;
  }
}
