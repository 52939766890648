.GameBoard__board___3Wdiu {
    height: 100%;
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
    display: grid;
    position: absolute;
    min-width: min-content;
    min-height: min-content;
}

.GameBoard__thumbnail___1qnUw {
    padding: 0.2em;
}

.GameBoard__boardElement___I5j-j {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GameBoard__arrowTarget___REAZw {
    display: inline-block;
}

.GameBoard__svgOverlay___3Iy46 {
    position: absolute;
    overflow: visible;
}
