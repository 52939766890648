.AWSProduct__awsProduct___1opIx {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.AWSProduct__awsProductIcon___278J4 {
  flex-grow: 1;
  background-position: center;
  background-repeat: no-repeat;
}

.AWSProduct__awsProductName___3XJ4H {
  color: white !important;
  border: solid 0.1em white;
  border-radius: 1em;
  padding: 0 0.7em;
  font-size: 1.7vh !important;
  display: block;
  background-color: #ffffff1c;
  margin: 0 1em !important;
  overflow: hidden;
  text-align: center;
}

.AWSProduct__awsProductNameWrapper___fsvsy {
  margin: 0 auto 0.5em auto;
  max-width: -webkit-fill-available;
}
