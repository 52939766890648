.GamePageSnackbar__snackbar___1pgyu {
  margin-bottom: 4em;
}

/* remove offset for mobile snackbar */
@media (max-width: 959.95px) {
  .GamePageSnackbar__snackbar___1pgyu {
    margin-bottom: initial;
  }
}

.GamePageSnackbar__closeButton___1Nrd2 {
  color: #f44336 !important;
  margin: 0 0 0 0.5em !important;
  padding: 0 !important;
}
