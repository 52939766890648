.EndScreen__background___2P0u7 {
  min-height: fit-content;
  height: 100%;
  box-sizing: border-box;
  color: white;
  padding-top: 5em;
  padding-bottom: 1em;
  animation: EndScreen__bg___Fm6uW 3s forwards;
  animation-delay: 0.4s;
}

@keyframes EndScreen__bg___Fm6uW {
  from {
    background-color: white;
  }
  to {
    background-color: #1b1b1b;
  }
}

.EndScreen__credits___3ySgA > hr {
  margin-top: 7em;
  margin-bottom: 1em;
}

.EndScreen__credits___3ySgA {
  color: rgb(158, 158, 158);
}

.EndScreen__italic___2ZIBz {
  font-style: italic;
}

.EndScreen__backButton___1Jie8 {
  color: white !important;
  margin-top: 1em !important;
}
