.MarkdownViewer__markdownContainer___2FJbT {
  font-family: "Roboto", sans-serif;
  padding: 1.5em;
  text-align: left;
}

.MarkdownViewer__markdownContainer___2FJbT pre {
  background-color: #f6f8fa;
  padding: 1em;
  border-radius: 5px;
}

.MarkdownViewer__markdownContainer___2FJbT a {
  color: #1b69b6;
  text-decoration: none;
}

.MarkdownViewer__markdownContainer___2FJbT a:hover {
  text-decoration: underline;
}

.MarkdownViewer__markdownContainer___2FJbT h1, .MarkdownViewer__markdownContainer___2FJbT h2 {
  padding-bottom: .3em;
  border-bottom: 1px solid #eaecef;
}

.MarkdownViewer__materialIcons___1WSrJ {
  font-size: 1em !important;
  vertical-align: middle;
  margin-left: 0.2em;
}
